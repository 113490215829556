.service-body-cont{
 
    padding: 6.25em 7em;
    background: #FEF0D8

}
.service-body-header{
    margin-bottom: 40px;
}
.service-body-header > h1{
    
font-size:3rem;
font-weight: 600;
line-height: 72.8px;
letter-spacing: -0.005em;
text-align: center;


}

.service-body-boxes{
    display: flex;
    gap: 40px;
    margin-top: 60px;
}
.service-body-boxes > div {
   
    padding: 2em;
    border-radius: 12px;
    background: #FFFFFF;
   
}
.service-body-boxes > div > small{
    
    height: 23px;
    padding: .4em .75em;
    background: #ECE9E4;
    border-radius: 20px;
    color: #000000;
    

}
.service-body-boxes > div > h4{
   
    
    font-size: 1.75rem;
    font-weight: 500;
    line-height: 39.2px;
    text-align: left;
    margin: 24px 0px;

    

}
.service-body-boxes > div > p{
   
    
    font-size: 1.12rem;
    font-weight: 300;
    line-height: 27px;
    text-align: left;
    color: #4B4F57;


}

@media only screen and (max-width: 1024px) {
    .service-body-cont{
        padding: 6.25em 3em;
        
    
    }
   
}

@media only screen and (max-width: 768px) {
    .service-body-cont{
 
        padding: 6.25em 3em;
        background: #FEF0D8
    
    }

    .service-body-header > h1 {
       
        font-size: 2.8rem;
        font-weight: 500;
        line-height: 62.4px;
        letter-spacing: -0.01em;
        text-align: center;

    }
    .service-body-boxes {
        gap: 24px;

    }
    .service-body-boxes > div > h4 {
        font-size: 1.5rem;
    }
    .service-body-boxes > div {
        padding: 2em 1em;
        flex: 1;
    }
}

@media only screen and (max-width: 768px) {
    .service-body-cont{
 
        padding: 6.25em 1.5em;
    }

    .service-body-header > h1 {
       
        font-size: 2.25rem;
        font-weight: 500;
        line-height: 46.8px;
        letter-spacing: -0.01em;
        text-align: center;

    }
    .service-body-boxes {
        flex-direction: column;

    }
    .service-body-boxes > div > h4 {
        font-size: 1.5rem;
    }
    .service-body-boxes > div {
        padding: 2em 1em;
       
    }
}