.get-started-container{
    
    padding: 0em 1.5em 2em;
    background: #EEF4FA;

}
.get-started-main{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 24px;
    padding: 6.25em 5.5em;
    background: linear-gradient(120deg, rgba(0, 19, 46, 0.94) -17%, rgba(2, 7, 18, 0.96) 49.08%), url('../../../assets/getStarted.png');
    background-size: 100%; 
    background-position: center;
}
.get-started-left{
    width: 37%;
}
.get-started-left p{
    font-size: 3rem;
    font-weight: 600;
    line-height: 62.4px;
    letter-spacing: -0.04em;
    color: rgba(255, 255, 255, 1)

    
}
.get-started-right{
    width: 52%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.get-started-right p{
    

font-size: 1.25rem;
font-weight: 400;
line-height: 30.6px;
color: rgba(243, 242, 239, 1);
}


@media only screen and (max-width: 768px) {
    .get-started-main{
        flex-direction: column;
        padding: 6.25em 3em;
    }
    .get-started-left{
        width: 100%;
        margin-bottom: 32px;
    }
    .get-started-right{
        width: 100%;
        align-items: center;
    }
    .get-started-left p{
        text-align: center;
    }
    .get-started-right p{
        text-align: center;
    }
}