.tos_container{
    background: #FFFFFF;
    padding: 2em 7em 5em;
    min-height: 100vh;
    
}
.tos_container h1{
    font-size: 4em;
}
.tos_container p{
    text-align: justify;
}
.indent{
    padding-left: 2em;
}

@media only screen and (max-width: 768px) {
    .tos_container{
        
        padding: 2em 3em 5em;
        
    }
    .tos_container h1{
        font-size: 3.5em;
        text-align: center;
    }
}
@media only screen and (max-width: 480px) {
    .tos_container{
        
        padding: 2em 1.5em 5em;
        
    }
    .tos_container h1{
        font-size: 3em;
        text-align: center;
    }
}