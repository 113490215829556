.contact-body-container{
    display: flex;
    justify-content: space-between;
    background: #EEF4FA;
    padding: 5em 7em;
    align-items: center

}
.contact-body-header{
    margin-bottom: 24px;
}
.contact-body-header h1{

    font-size: 3.5rem;
    font-weight: 600;
    line-height: 67.2px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #000000;
    
}
.contact-body-content{
    margin-bottom: 80px;
}
.contact-body-content h5{
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: -0.01em;
    text-align: left;
  
    color: #4B4F57;

}
@media only screen and (max-width: 1024px) {
    .contact-body-container {
        padding: 5em 3em;
        
    }

}
@media only screen and (max-width: 960px) {
    .contact-body-container {
        padding: 5em 3em;
        flex-direction: column;
        gap: 40px;
    }
    

}
@media only screen and (max-width: 768px) {
    .contact-body-container {
        flex-direction: column;
        padding: 5em 3em;
        gap: 4em;
    
    }
    .contact-body-header h1 {
        font-size: 2.8rem;
        
    }
  
    
}

@media only screen and (max-width: 480px) {
    .contact-body-container {
        flex-direction: column;
        padding: 5em 1.5em;
        gap: 4em;
    
    }
    .contact-body-header h1 {
        font-size: 2.5rem;
        
    }
  
    
}