
.carousel {
    position: relative;
    width: 100%;
    
    margin: 0 auto;
  }
  
  .slide {
    position: relative;
    width: 100%;
  }
  
  .slide img {
    width: 100%;
    height: auto;
  }
  
  .indicators {
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    text-align: center;
  }
  
  .indicator {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color:transparent;
    margin: 0 5px;
    cursor: pointer;
    border-color: #FFFFFF;
    transition: background-color 0.3s ease;
  }
  
  .indicator.active {
    background-color: #FFFFFF;
  }
  .carousel-card{
    width: 404px;
    padding: 60px;
    border-radius: 20px;
    position: absolute;
    top: 100px;
    right: 20px;
    background-color: #FFFFFF;
  }
  .carousel-card h2{
    font-size: 2.25rem;
    margin-bottom: 32px;
    font-weight: 600;
  }
  .carousel-card h5 {
  
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 38.4px;
    letter-spacing: -0.02em;
    color: #4B4F57;
  }
  
  @media only screen and (max-width: 768px) {
    .carousel-card{
      top: 30px;
      right: 20px;
      background-color: #FFFFFF;
    }
  }
  
  @media only screen and (max-width: 768px) {
    .carousel-card{
      width: 300px;
      padding: 30px;
      top: 30px;
      right: 20px;
      background-color: #FFFFFF;
    }
    .carousel-card h2{
      font-size: 1.8rem;
      margin-bottom: 30px;
      font-weight: 600;
    }
    .carousel-card h5 {
    
      font-size: 1rem;
      font-weight: 400;
      line-height: 30px;
      letter-spacing: -0.02em;
    }
    
}

@media only screen and (max-width: 480px) {
  .carousel-card{
    width: 200px;
    padding: 10px;
    top: 20px;
    right: 10px;
    border-radius: 10px;
    background-color: #FFFFFF;
  }
  .carousel-card h2{
    font-size: 1rem;
    margin-bottom: 10px;
    font-weight: 600;
  }
  .carousel-card h5 {
  
    font-size: .8rem;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: -0.02em;
  }
  
   
}