.about_header{
    padding: 5em 7em; 
}
.about_header h2{
    
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: -0.01em;
    margin-bottom: 2.25rem;
}
.about_header p{

    font-size: 3.5rem;
    font-weight: 600;
    line-height: 72.8px;
    letter-spacing: -0.02em; 
    width: 71%;   
}

@media only screen and (max-width: 1024px) {
    .about_header{
        padding: 5em 3em; 
    } 
   
}
@media only screen and (max-width: 768px) {
   
    .about_header{
        padding: 5em 3em; 
    }
    
    .about_header p {
        font-size: 3.3rem;
        width: 100%;
    }
}

@media only screen and (max-width: 480px) {
   
    .about_header{
        padding: 5em 1.5em; 
    }
    .about_header p {
        font-size: 2.5rem;
        width: 100%;
    }
}
