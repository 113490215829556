.hero-container{
    padding: 6.25em 7em 0em;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: rgba(2, 7, 18, 1);
    /* background-image: url(../../../assets/herobg.svg);
    background-size: 100%;
    background-position: center; */
    height: 660px;
    position: relative;
}
.hero-container > h6{

   
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: -0.01em;
    text-align: center;
    color:#FFFFFF;
    opacity: 60%;

}
.hero-container >h1 {
   
font-size:4.5rem;
font-weight: 700;
line-height: 86.4px;
letter-spacing: -0.02em;
text-align: center;
color:#FFFFFF;

}
.hero-container p{
    
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 36px;
    letter-spacing: -0.01em;
    margin: 26px 0px;
    color: rgba(255, 255, 255, 1);
    text-align: center;
}
.hero-img {
    position: absolute;
    margin-top: 23em;
    
}
.hero-img > img{
    width: 100%;
}
.hero-access{
    display: flex;
    gap: 32px
}
.hero-access > div{
    display: flex;
    align-items: center;
    gap: 8px;
}
.hero-access > div a{
    
    font-size: 1.1rem;
    line-height: 30.6px;
    text-decoration: none;
    color: rgba(255, 255, 255, 1);
    font-weight: 500;
    white-space: nowrap;

}
.hero-access > div a:hover{
    color: #DDD8D0;
}

.hero-bottom{
    height: 625px;
    background: rgba(0, 0, 0, 1)
}

@media only screen and (max-width: 1024px) {
    .hero-container{
        padding: 6.25em 4.2em 0em;    
    }
    .hero-img{
        padding-left: 4.2em;
        padding-right: 4.2em;
        margin-top: 28em;
    }
} 

@media only screen and (max-width: 768px) {
    .hero-container{
        padding: 6.25em 4.2em 0em;    
    }
    .hero-img{
        padding-left: 4.2em;
        padding-right: 4.2em;
        margin-top: 28em;
    }
    
    .hero-container >h1
    {
        font-size: 3.5rem;
        font-weight: 700;
        color:#FFFFFF;
        line-height: 72px
    }
    .hero-bottom{
        height: 400px;
    }
}
@media only screen and (max-width: 480px) {
    .hero-container{
        padding: 6.25em 1.5em 0em;    
    }
    
    .hero-bottom{
        height: 200px;
    }
    .hero-img{
        padding-left: 1.5em;
        padding-right: 1.5em;
        margin-top: 38em;
       
    }
    .hero-access{
        gap: 16px;
    }
}