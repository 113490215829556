.Faq-container{
    display: flex;
    justify-content: space-between;
    background: #EEF4FA;
    padding: 7.5em 7em;
    align-items: center;
}
.Faq-left{
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 34%
}
.Faq-left h1{
  
    font-size: 3rem;
    font-weight: 600;
    line-height: 62.4px;
    letter-spacing: -0.04em; 
}
.Faq-left p{  
    
    font-size: 1.12rem;
    font-weight: 400;
    line-height: 30.6px;
}
.Faq-left > div {
    display: flex;
    gap: 32px;
    align-items: center;
}
.Faq-left > div  a {
    text-decoration: none;
    
    font-size: 1rem;
    font-weight: 500;
    line-height: 26.4px;
    color: #007EFF;

}
.Faq-left > div > a:hover{
   
    color: rgba(184, 179, 167, 1)

}
.Faq-left > div > div{
    display: flex;
    align-items: center;
    gap: 8px;
    background: rgba(255, 255, 255, 1);
    padding: 1em;
    border-radius: 12px;
    border: 1px solid rgba(221, 216, 208, 1)


}
.Faq-left > div > div:hover{
    background: rgba(251, 251, 249, 1)


}
/* .Faq-left > div > div > a{
    text-decoration: none;
    
    font-size: 1rem;
    font-weight: 500;
    line-height: 26.4px;
    color: rgba(16, 25, 40, 1)
} */

.accordion-content{
    overflow: hidden;
    max-height: 0px;
   
    transition: max-height 0.5s ease;
    
}

.accordion-content.open{
    max-height: 150px;
    transition: all 0.5s ease-in;
    margin-bottom: 1em;
}

.accordion-header{
    display: flex;
    justify-content: space-between;
    border-top: 1px solid rgba(221, 216, 208, 1);  
    align-items: center;
}
.accordion-question.open{
    color: #007EFF;
}
.accordion-question{
  
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: -0.02em;
    padding: .8em 0em;
    cursor: pointer;
      
}
.accordion-content p{

    
    font-size: .9rem;
    font-weight: 400;
    line-height: 21px;
    color: #4B4F57

}
.Faq-right{
    width: 63%;
}

@media only screen and (max-width: 1024px) {
   
    .Faq-container{
        padding: 7.5em 3em;
    }
}
@media only screen and (max-width: 768px) {
   
    .Faq-container{
        display:flex;
        flex-direction: column;
        padding: 7.5em 3em;
    }
    .Faq-left{
     
        width: 100%;
        margin-bottom: 60px;
    }
    .Faq-right{
        width: 100%;
    }
}

@media only screen and (max-width: 480px) {
   
    .Faq-container{
        display:flex;
        flex-direction: column;
        padding: 7.5em 1.5em;
    }
}


