
.nav-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0em 7.2em;
    height: 66px;
    position: sticky;
    top: 0px;
    z-index: 10;
    
    

}
.nav-links{
    display: flex;
    align-items: center;
    gap: 2em;
}
.nav-links ul{
    display: flex;
    gap: 2.2em;
}
.nav-links ul li{
    list-style: none;
}
.nav-links.alt ul li > a.active{
    color: #007EFF;
}
.nav-links ul li > a.active{
    color: #007EFF;
}
.nav-links ul li  > a{
    text-decoration: none;
    color:#FBFBF9;
    font-size: 1.13rem;
    line-height: 30.6px;
    
}
.nav-links ul li > a:hover{
    color: #007EFF;
}
.nav-links.alt ul li >a{
    color: #101928;
}

.nav-links.alt ul li >a:hover{
    color: #007EFF;
}
.nav-container button{

font-size: .88rem;


}

.nav-products{
    display: flex;
    align-items: center;
    gap: .5em;

}
.nav-products.alt{
    color: #101928;
    font-weight: 400;
}


.nav-products-two{
    display: flex;
    align-items: center;
    gap: .5em;

}
.nav-products-icon{
    font-size: 23px;
    color: #FFFFFF;
}
.nav-products-icon.alt{
    color: #000000;
}

.nav-products > a {
    text-decoration: none;
    color: #FBFBF9;
    font-weight: 400;
    font-size: 1.13rem;
    line-height: 30.6px;
    
}

.nav-products-two > a {
    text-decoration: none;
    color: #007EFF;
    font-weight: 500;
    font-size: 1.13rem;
    line-height: 30.6px;
    
}
.nav-products >a:hover {
    color: #007EFF;
}
.nav-products-container{
    position: relative;
}

.nav-products-container_two{
    position: relative;
}
.nav-products-container_two li.active > div > a{
    color: #007EFF;
}
.nav-options {
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 1em;
    background: #FFFFFF;
    border-radius: 8px;
    height: auto;
    top: 42px;
    padding: 1em 2.35em 1em 1em;
    white-space: nowrap;
    justify-content: center;
    border: 1px solid rgb(117, 117, 117);

}
.nav-options > a{
    font-size: .8rem;
    font-weight: 300;
    line-height: 21px;
    color: #101928;
    text-decoration: none;

}
.nav-options a:hover{
    color: #007EFF
}
.nav-options-side{
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 1em;
    background: #FFFFFF;
    border-radius: 8px;
    height: auto;
    top: 42px;
    padding: 1em 2.35em 1em 1em;
    white-space: nowrap;
    justify-content: center;
}
.nav-side-links{
    font-size: .8rem;
    line-height: 21px;
    color: #101928;
    text-decoration: none;
}
.menu-button{
    display: none;
}
.menu-button.alt{
    color: #FFFFFF;
}
.sidebar {
    display: none; 
}

.sidebar.visible {
    position: fixed;
    top: 65px;
    right: 0;
    height: 100vh;
    width: 60%;
    z-index: 10;
    background-color:#020712;
    /* box-shadow: -10px 0 10px white; */
    display: flex; 
    flex-direction: column;
    padding: 2em 1.5em;
   
}


.sidebar li{
    width: 100%;
    margin-bottom: 30px;
    list-style-type: none;
    
}
.sidebar  a{
    color: #FFFFFF;
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 30.6px;
    letter-spacing: -0.02em;
    text-decoration: none;
   
}
@media only screen and (max-width: 1024px) {
    .nav-container{
        padding: 0em 3em;
    
    }

}
@media only screen and (max-width: 768px) {
    .nav-links ul{
        display: none;
    }
    .nav-container{
        padding: 0em 3em;
    
    }
    .menu-button{
        display: block;
    }
    .nav-options > a{
        font-size: .8rem;
        line-height: 21px;
        color: #101928;
        text-decoration: none;
    
    }
    .nav-options{
        top: 39px;
        left: 32px;
    }


}
@media only screen and (max-width: 480px) {
    .nav-links ul{
        display: none;
    }
    .nav-container{
        padding: 0em 1.5em;
    
    }
    .menu-button{
        display: block;
    }
    .nav-options{
        top: 39px;
        right: 62px;
    }
    .nav-options > a{
        font-size: .8rem;
        line-height: 21px;
        color: #101928;
        text-decoration: none;
    
    }


}