.service-hero-container{
    padding: 7.5em 7em;
    background: #020712;
    /* background-image: url(../../../assets/servicesbg.svg); */
    background-size: 100%;
    background-position: center;
    
}
.service-hero-container img{
    width: 100%;
}
.service-hero-header{
    padding: 0em 12em;
    margin-bottom: 26px;
}
.service-hero-header > h1{
    
font-size: 56px;
font-weight: 600;
line-height: 72.8px;
letter-spacing: -0.02em;
text-align: center;
color: #FFFFFF;


}
.service-hero-text{
    margin-bottom: 26px;
    padding: 0em 12em;
}
.service-hero-text h6{
    
font-size:1.25rem;
font-weight: 300;
line-height: 32px;
letter-spacing: -0.005em;
text-align: center;
color: #FFFFFF;


}

.service-hero-btn{

    display: flex;
    justify-content: center;
    margin-bottom:60px
    
}
.service-hero-btn button{

height: 56px;
padding: 0em 1.5em;
border-radius: 12px;
border: 1px solid #DDD8D0 ;
font-size: 1rem;
font-weight: 500;
line-height: 26.4px;
text-align: center;
font-family: Graphik;
color: #4B4F57;
background: #FFFFFF;

}
.service-hero-btn button:hover{
    background: #F3F2EF;
}
.quote-container{
    width: 736px;
    height: 500px;
    overflow-x: hidden;
    overflow-y: auto;
    padding:8px;

}
.quote-container h4{
    
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 31.2px;
    text-align: center;
    color: #000000;
    margin: 20px 0px 16px;
}
.quote-container p{
 
    font-size: .8rem;
    font-weight: 400;
    line-height: 22.4px;
    letter-spacing: -0.015em;
    text-align: center;
    margin-bottom: 20px;

}
.quote-input-container{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    column-gap: 18px;
    row-gap: 24px;
    margin-bottom: 24px;
}
.quote-input-container input{
    width: 95%;
    height: 44px;
    border-radius: 6px;
    border: 1px solid #DDD8D0;
    padding: 0em .75em;
    outline: none;
}
.quote-input-container ::placeholder{
    font-family: Graphik;

    font-size: .8rem;
    font-weight: 400;
    line-height: 22.4px;
    letter-spacing: -0.015em;
    color: #98A2B3;
    outline: none;

}
.quote-container label{
    /* margin: 4px 0px; */

    font-size: .8rem;
    font-weight: 500;
    line-height: 22.4px;
    text-align: left;
    color: #475367;

}
.quote-container button{
    width:100%;
    height: 44px;
    padding: 8px 16px 8px 16px;
    border-radius: 8px;
    background: #007EFF;
    outline: none;
    color: #FFFFFF;
    border: 1px solid #007EFF;
    font-family: Graphik;
    font-size: .8rem;
    font-weight: 500;
    line-height: 22.4px;
    text-align: center;
    margin-top: 20px;


}
.non-input-quote{
    display: flex;
    flex-direction: column;
}
.non-input-quote select{
    margin-top: 3px;
    height: 46px;
    outline: none;
    border-radius: 6px;
    border: 1px solid #DDD8D0;
}
.non-input-quote textarea{
    height: 320px;
    outline: none;
    border-radius: 6px;
    border: 1px solid #DDD8D0;
    padding: 1em .75em;
}
.non-input-quote small{
   
font-size: .8rem;
font-weight: 400;
line-height: 22.4px;
letter-spacing: -0.015em;
color: #9CA0A5;
margin-top: 8px;

}
@media only screen and (max-width: 1024px) {
    .service-hero-container{
        padding: 7.5em 2em;   
    }
    .service-hero-header{
        padding: 0em 2em;
        
    }

}

@media only screen and (max-width: 768px) {
    .service-hero-container{
        padding: 7.5em 3em;   
    }
    .service-hero-header{
        padding: 0em 4em;
        
    }
    .service-hero-header > h1 {
        font-size: 3rem;
    }
    .service-hero-text {
        padding: 0em 4em;
    }
    .service-hero-text h6 {
        font-size: 1.12rem;
    }
    .quote-container{
        padding: 8px 20px 8px 8px;
        width: 630px;
    }
}
@media only screen and (max-width: 480px) {
    .service-hero-container{
        padding: 7.5em 1.5em;   
    }
    .service-hero-header{
        padding: 0em 1.5em;
        
    }
    .service-hero-header > h1 {
        font-size: 2.5rem;
    }
    .service-hero-text {
        padding: 0em 1.5em;
    }
    .service-hero-text h6 {
        font-size: 1rem;
    }
    .quote-input-container{
      
        grid-template-columns: repeat(1,1fr);
    
    }
    .quote-input-container input{
        width: 90%;
    }
    .quote-container{
        padding: 4px 10px 4px 4px;
        width: 275px;
    }
    .quote-container h4{
        margin-top: 55px
    }

}