.insights-container{
    padding: 7.5em 7em;
    background: #EEF4FA;
}
.insights-header h1{
    font-size: 3rem;
    line-height: 57px;
    font-weight: 600;
    margin-bottom: 24px;
}
.insights-header p{
    font-size: 1.25rem;
    line-height: 21px;
    margin-bottom: 60px;
}
.insights-section-one{
    display: flex;
    gap: 60px;
    margin-bottom: 6.25em
}
.insights_consumer_contributor{
    display: flex;
    flex-direction: column;
    padding: 0em 0em 0em .7em;
    /* border-left: 2px solid black; */
    width: 32%;

}

.insights-img-box{
    width: 70%;
}
.insights-img-box img{
    width: 100%;
    height: 360px;
    border-radius: 20px
}
.insights_consumer,
.insights_contributor {
    border-left: 2px solid #000; /* Adjust border properties as needed */
    padding-left: 10px; /* Adjust padding as needed */
}


.insights_consumer{
    display: flex;
    gap: 16px;
    flex-direction: column;
    height: 158px;
    
}
.insights_consumer h3{
font-size: 1.2rem;
font-weight: 500;

line-height: 32px;
cursor: pointer;

}
.insights_consumer a{
    text-decoration: none;
    color: #000000;
    font-weight: 500;

}
.insights_consumer p{
font-weight: 400;

line-height: 26px;
}
.insights_consumer div{
    display: flex;
    align-items: center;
    
}
.insights_consumer.clicked{
    border-left: 2px solid #007EFF; 
}
.insights_consumer a:hover{
    color: #DDD8D0;
}
.insights_consumer div .icon{
    font-size: 20px;   
   }
   .insights_consumer div:hover .icon{
       
       color: #DDD8D0;
   }

.insights_contributor{
    display: flex;
    gap: 16px;
    flex-direction: column;
   height: 158px;
}
.insights_contributor h3{
    font-size: 1.2rem;
font-weight: 500;

line-height: 32px;
cursor: pointer;
}
.insights_contributor a{
    text-decoration: none;
    color: #000000;
    font-weight: 500;
    
}
.insights_contributor a:hover{
    color: #DDD8D0;
}
.insights_contributor.clicked{
    border-left: 2px solid #007EFF;
}
.insights_contributor div{
    display: flex;
    align-items: center;

    
}
.insights_contributor div .icon{
 font-size: 1.2rem;   
}
.insights_contributor div:hover .icon{
    
    color: #DDD8D0;
}
.insights_contributor p{

    
    line-height: 26px;
}
.insights_user_feature{
    display: flex;
    flex-direction: column;
    gap: 1em;
    width: 62%;
}
.insights_user_feature div{
    display: flex;
    align-items: center;
    padding: 1.5em 2em;
    gap: 10px;
    border-radius: 8px;
    background: #FFFFFF;

}
.insights_user_feature div p{
    font-size: 1.7rem;
}
.insights-sec-two-header{
    display: flex;
    justify-content: space-between;
    margin-bottom: 5em;
}
.insights-sec-two-header h1{
    font-size: 3rem;
    width: 46%;
    font-weight: 500;
}
.insights-sec-two-header p{
    font-size: 1.125rem;
    width: 29%;
    line-height: 30.6px;
    text-align: left;
    color: #4B4F57;
}
.highlight_consumer{
    color: #007EFF
}
.insights-data-flow{
    display: flex;
    gap: 20px;
}
.insights-data-flow div{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.insights-data-flow div > h4{
   font-size: 1.2rem;
   
   font-weight: 500;
   line-height: 30.6px;
}
.insights-data-flow div >p{
    font-size:1rem;
    line-height:21px;
    color: #081E4A;
    font-weight: 300;
    line-height: 22.4px;
    letter-spacing: -0.015em;
    text-align: left;
    

 }
 .insights-data-flow-cont{
     display: flex;
     flex-direction: column;
     gap: .5em;
   
 }
 .line-flow{
    display: flex;
    justify-content: center;
    width: 15%;
 }
 .insights-section-three{
     display: flex;
     align-items: center;
     justify-content: space-between;
 }


 @media only screen and (max-width: 1024px) {
    .insights-container{
        padding: 7.5em 3em;
       
    }
 }

 @media only screen and (max-width: 768px) {
   
    .insights-container{
        padding: 7.5em 3em;
        background: #EEF4FA;
    }
    .insights-sec-two-header h1{
        width:  48%;
    }
    .insights-sec-two-header p{
        width: 44%;
    }
    .insights-section-three{
        flex-direction: column;
        align-items: baseline;
        gap: 20px;
    }
    .insights-img-box{
        width: 100%;
    }
}

@media only screen and (max-width: 480px) {
    .insights-container {
        padding: 7.5em 1.5em;
        background: #EEF4FA;
    }
    .insights-header h1 {
        font-size: 2.5rem;
    }
    .insights-section-one{
        flex-direction: column;
    }
    .insights_consumer_contributor,
    .insights_user_feature { 
        width: 100%;
    }
    .insights_user_feature div p {
        font-size: 1.12rem;
    }
    .insights-sec-two-header {
        flex-direction: column;
    }
    .insights-sec-two-header h1{
        width: 100%;
        margin-bottom: 60px
    }

    .insights-sec-two-header p {
        width: 100%;
    }
    .insights-section-three {
        align-items: baseline;
        flex-direction: column;
    }
    .insights-img-box{
        width: 100%;
    }
    
}
   
    

