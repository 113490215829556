.policy_container{
    background: #FFFFFF;
    padding: 2em 7em 4em;
    min-height: 100vh;
}
.policy_container h1{
    font-size: 4em;
}

.policy_container p{
    text-align: justify;
}
.indent{
    padding-left: 2em;
}

@media only screen and (max-width: 768px) {
    .policy_container{
        
        padding: 2em 3em 5em;
        
    }
    .policy_container h1{
        font-size: 3.5em;
        text-align: center;
    }
}
@media only screen and (max-width: 480px) {
    .policy_container{
        
        padding: 2em 1.5em 5em;
        
    }
    .policy_container h1{
        font-size: 3em;
        text-align: center;
    }
}