.service-hero-btn{

    display: flex;
    justify-content: center;
    margin-bottom:60px
    
}
.quote-container{
    position: relative;
}
.service-hero-btn button{

    height: 56px;
    padding: 0em 1.5em;
    border-radius: 12px;
    border: 1px solid #DDD8D0 ;
    font-size: 1rem;
    font-weight: 500;
    line-height: 26.4px;
    text-align: center;
    font-family: Graphik;
    color: #4B4F57;
    background: #FFFFFF;
    cursor: pointer;

}
.quote-success{
    display: flex;
    align-items: center;
    flex-direction: column;
}
.quote-success div{
    max-width: 300px;
    height: 300px;
}
.quote-success img{
    width: 100%;
}
.service-hero-btn button:hover{
    background: #F3F2EF;
}
.closeIcon-quote{
position: absolute;
top: 0px;
right: 20px;
}