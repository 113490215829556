.access-btn{
    border-radius: 12px;
    border: 1px solid rgba(0, 126, 255, 1);
    background: #007EFF;
    font-family: Graphik;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    text-align: center;
    color: #FFFFFF;
    white-space: nowrap;
    
}

.access-btn:hover{
    background: #3398FF
}
.modal-content{
    margin:2em 2em;
    width: 381px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    
}
.modal-content h4{
   
    font-size:  1.75rem;
    font-weight: 600;
    line-height: 36.4px;
    letter-spacing: -0.01em;
    text-align: center;
    padding: 0em 2em;

}
.modal-content > p{
    
    
    font-size: 1rem;
    font-weight: 400;
    line-height: 26.4px;
    text-align: center;
    width: 96%;
    color: #4B4F57;

}
.left-img{
    display: flex;
    justify-content: center
}
.modal-content input{  
    height: 44px;
    padding: 0rem .75rem ;
    border-radius: 6px;
    border: 1px solid #DDD8D0;
    outline: none;
}
.modal-content input::placeholder{
    
    
    font-size: .88rem;
    font-weight: 400;
    line-height: 21px;
    color: #B8B3A7;

}
.modal-content > button{

height: 44px;
/* padding: 0em 1em; */
border-radius: 8px;
text-align: center;
background: #007EFF;
color: #FFFFFF;
border: 1px solid #007EFF;

font-size: .88rem;
font-weight: 500;
line-height: 21px;


}
.modal-content div p{
    

font-size: .75rem;
font-weight: 400;
line-height: 17.4px;
text-align: center;
color: #4B4F57;
padding: 0em 2em;
}
.modal-content div p a{
    
    
    font-size: .75rem;
    font-weight: 400;
    line-height: 18.6px;
    text-align: center;
    color: #4B4F57;
    border-bottom: 1px solid #4B4F57;
    text-decoration: none;
}
.right-content{
   position: relative;
   width: 391px;
}
.right-content-img{
    width: 100%;
    object-fit: cover;
    height: 502px;
    border-radius: 0px 20px 20px 0px;
}
.closeIcon{
    border-radius: 0px;
    position: absolute;
    right: 20px;
    top: 20px;
}
.closeIcon_two{
    border-radius: 0px;
    position: absolute;
    display: none;
    right: 20px;
    top: 10px;
}

@media only screen and (max-width: 480px) {
    .right-content{
        display: none;
    }
    .modal-content{
        width: 250px
    }
    .modal-content div p{

        padding: 0em .5em;
    }
    .modal-content h4{
        padding: 0em ;
        font-size: 1.5rem;
        margin-top: 20px;
    }
    .closeIcon_two{
        display: block;
    }
}