*{
    margin: 0;
    padding: 0;
}
.problem-container{
    background: #EEF4FA;
    padding: 7.5em 7em;
    display: flex;
    flex-direction: column;
    gap: 80px;

}
.problem-container-1{
display: flex;
justify-content: space-between;
align-items: center
}
.problem-container-2{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 32px;
 }
.problem-grid-one{
    display: flex;
    flex-direction: column;
    gap: 12px;
    
}
.problem-grid-one > h3{
    font-size: 1rem;
    font-weight: 500;
    background: #FFFFFF;
    padding: .3em .8em;
    border-radius: 20px;
    width: 14%;
    color: #4B4F57
}
.problem-grid-one > p{
    color: #101928;
    font-size: 3rem;
    font-weight: 700;
    line-height: 67.2px;
    width: 70%;
}
.problem-grid-two{
  white-space: nowrap;
}
.problem-grid-two > div{
    display: flex;
    gap:.4em;
    align-items: center;
    margin-top: 12px;
}
.problem-grid-two > div p{

font-size: 1.25em;
font-weight: 500;
line-height: 32px;
color: #000000;
}


.video-responsive iframe {
    width: 700px;
    height: 400px;
    border-radius: 12px;
}

.horizontal-line {
    border-top: 1px solid #F3F2EF 
    
}
.problem-grid-four{
    background: linear-gradient(120deg, rgba(2, 7, 18, 0.8) -17%, rgba(2, 7, 18, 0.96) 49.08%), url('../../../assets/getStarted.png');
    background-size: 100%; 
    background-position: center;
    padding: 1em 2em;
    width: 400px;
    height: 370px;
    border-radius: 12px 12px 80px 12px;
}
.problem-grid-four h1{
    color: #F6F6F6;
  
    font-size: 4.5rem;
    font-weight: 600;
    line-height: 79.2px;
    letter-spacing: -0.01em;
}
.problem-grid-four span{
    color: #007EFF;
}
.problem-grid-four > p{
    margin-top: 15px;
    color: #F3F2EF;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: -0.005em;
    

}
.source-info{
    margin-top: 7em;
    display: flex;
    align-items: center;
    gap: .5em;
}
.source-info p{
    
font-size: .8rem;
font-weight: 400;
line-height: 22.4px;
letter-spacing: -0.015em;
color: #F3F2EF;


}
.source-info h4{
    width: 64px;
}


@media only screen and (max-width: 1024px) {
   
    .problem-container{
        padding: 7.5em 3em;
    }
    .video-responsive iframe {
        width: 500px;
    
    }
    .problem-grid-one > h3{
        width:18%;
        
     }
}

@media only screen and (max-width: 768px) {
   
    .problem-grid-one > h3{
       width: 30%;
       
    }
    .problem-grid-one p{
        font-size: 2.25rem;
        line-height: 46.8px;
        letter-spacing: -0.003em;
        width: 100%;

    }
    .problem-grid-two > div p{

        white-space: nowrap;
    }

    .problem-container{
        padding: 7.5em 3em;
    }
    .problem-grid-three {
        width: 100%;
    }
    .video-responsive iframe {
        width: 360px;
    
    }
    .problem-grid-four{
        width: 300px;
    }
    .source-info{
        margin-top: 5em;
        display: flex;
    }
}
@media only screen and (max-width: 480px) {


    .problem-container{
        padding: 7.5em 1.5em;
    }
    .problem-container-1{
        flex-direction: column;
        align-items: baseline;
    }
    .problem-grid-one p{
        width: 100%;
    }
    .problem-container-2{
        flex-direction: column;
    }
    .problem-grid-three {
        width: 100%;
    }
    .video-responsive iframe {
        width: 327px;
    
    }
    .problem-grid-four{
        width: 270px;
    }
    

}