.mission-container{
    padding: 7.5em 7em;
}
.mission-header{
   
    padding: 0em 1em;
}
.mission-header h1{
    
    font-size: 3.5rem;
    font-weight: 600;
    line-height: 72.8px;
    letter-spacing: -0.02em;
    text-align: center;
    color: #000000

}
.mission-content{
    display: flex;
    margin-top: 5em;
    gap: 2em;
}
.mission-content div{
    padding: 3.2em;
    border-radius: 1em;
    width: 50%;
}
.mission-content div h2{

    font-size: 3em;
    font-weight: 600;
    line-height: 62.4px;
    letter-spacing: -0.04em;
    text-align: left;
    color: #000000;
    margin-top: 32px;
    
}
.mission-content div hr{
    width: 10%;
    border-color: #F3F2EF;
    margin: 20px 0px 32px;
}
.mission-content div h3{
    
    
    font-size: 2rem;
    font-weight: 600;
    line-height: 44.8px;
    letter-spacing: -0.02em;
    text-align: left;
    color: #272B32;
    margin-bottom: 32px;

}
.mission-content div h5{
    
    
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 38.4px;
    letter-spacing: -0.01em;
    text-align: left;

}

@media only screen and (max-width: 1024px) {
    .mission-container {
        padding: 7.5em 3em;
    }
}

@media only screen and (max-width: 768px) {
    .mission-container {
        padding: 7.5em 3em;
    }
    .mission-header{
   
        padding: 0em;
    }
    .mission-header h1{
   
    
        line-height: 62px;
    }
    .mission-content div {
        padding: 3.2em 2em;
    }
    .mission-content div h2 {
        font-size: 2.5em;
    }
    .mission-content div h3,
    .mission-content div h5 {
        font-size: 1.5em;
    }
   
    
}

@media only screen and (max-width: 480px) {
   
    .mission-container {
        padding: 7.5em 1.5em;
    }
    .mission-header h1{
   
        font-size: 2.25rem;
        line-height: 46px;
    }
    .mission-content {
        flex-direction: column;
    }
    .mission-content div {
        padding: 3.2em 1.5em;
        width: 87%;
    }
    .mission-content div h2 {
        font-size: 2em;
    }
    .mission-content div h3,
    .mission-content div h5 {
        font-size: 1.12em;
    }
}