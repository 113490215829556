.footer{
    padding: 2.5em 7em;
    background: rgba(2, 7, 18, 1);
    display: flex;
    flex-direction: column;
}
.footer-link{
   display: flex;
   flex-direction: column;
   column-gap: 24px;
    
}
.footer-link h4{
    color:rgba(184, 179, 167, 1);
    
    font-size: 1rem;
    font-weight: 500;
    line-height: 26.4px;
}
.footer-link ul li{
    list-style-type: none;
    margin-top: 2em;
}
.footer-link ul li a{
    
    font-size: 1rem;
    font-weight: 500;
    line-height: 26.4px;
    color: rgba(251, 251, 249, 1);
    text-decoration: none;  
}
.footer-link-career{
    display: flex;
    gap: .3em;
    margin-top: 1.25em;
    align-items: center;
}
.footer-link-career p{
    color: rgba(75, 79, 87, 1);
    
    font-size: .8rem;
    font-weight: 500;
    line-height: 18.6px;
    letter-spacing: -0.005em;
    background: rgba(243, 242, 239, 1);
    padding: 0em .6em;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 19px
}
.footer-subscribe{
    display: flex;
    margin-bottom: 40px;
    justify-content: flex-end;
}
.footer-sub-main{
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.footer-sub-main p{
    
    
    font-size: 1rem;
    font-weight: 500;
    line-height: 26.4px;
    color: rgba(221, 216, 208, 1)

}
.footer-sub-input{
    display: flex;
    gap: 8px;
    align-items: center;
}
.footer-sub-input div{
    display: flex;
    width: 382px;
    align-items: center;
    height: 44px;
    gap: 8px;
    padding: 0em 0.75em;
    border-radius: 6px ;
    border: 1px solid rgba(75, 79, 87, 1)

}
.footer-sub-input div>input{
    display: flex;
    align-items: center;
    background: inherit;
    border: none;
    outline: none;
    width: 100%;
    
    font-size: .8rem;
    font-weight: 400;
    line-height: 21px;
    color: rgba(184, 179, 167, 1);
}
.footer-sub-input div>input::placeholder{
    

font-size: .8rem;
font-weight: 400;
line-height: 21px;
color: rgba(184, 179, 167, 1);

}
.footer-sub-input > button{
   
    height: 40px;
    padding: 0em 5.2em;
    border-radius: 8px;
    border: 1px solid rgba(0, 126, 255, 1);
    background: rgba(0, 126, 255, 1);
    color: rgba(255, 255, 255, 1);   
    
    font-size: .8rem;
    font-weight: 500;
    line-height: 21px;    
}
.footer-sub-input > button:hover{
    background: #3398FF
}
.footer-bottom{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
}
.footer-bottom p{
   

font-size: .8rem;
font-weight: 400;
line-height: 21px;
color: rgba(156, 160, 165, 1);

}
.footer-bottom > div{
    display: flex;
    gap: 24px;
}

.footer_main_content{
    display: grid;
    grid-template-columns: repeat(4,1fr)
}

@media only screen and (max-width: 768px) {
   
    .footer{
        padding: 2.5em 3em;
        
    }
    .footer-sub-main{
        align-items: flex-end;
    }
    .footer-sub-main p{
        width: 72%;

    }
    .footer-sub-input div{
        width: 158px;
    }
}

@media only screen and (max-width: 480px) {
   
    .footer{
        padding: 2.5em 1.5em;
        
    }
    .footer_main_content{
        display: grid;
        grid-template-columns: repeat(2,1fr);
        row-gap: 32px;
    }
    .footer-subscribe {
        margin-top: 32px;
        justify-content: flex-start;
    }
    .footer-sub-main {
        align-items: flex-start;
    }
    .footer-sub-main p{
        width: 80%;

    }
    .footer-sub-input > button{
        width: 120px;
        padding: 0em;
    }
    .footer-bottom{
        flex-direction: column;
        row-gap: 24px;
    }
}
