.goals-container{
padding: 7.5em 7em;
background: #EEF4FA;
}
.goals-container h1{
 
font-size: 3.5rem;
font-weight: 600;
line-height: 72.8px;
letter-spacing: -0.02em;
text-align: left;
margin-bottom: 60px;

}
.goals-content{
    display: flex;
    gap: 44px;
}
.goals-content  > div {
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.goals-content div h5{


    font-size: 1.5rem;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: -0.01em;
    text-align: left;
    color: #77787C;
}

.goals-content div > div h4{
 
    font-size: 1.75rem;
    font-weight: 600;
    line-height: 36.4px;
    letter-spacing: -0.01em;
    text-align: left;
    color: #000000;

}
.goals-content div p{
    

font-size: 1.12rem;
font-weight: 400;
line-height: 30.6px;
text-align: left;
color: Grey/800;

}

@media only screen and (max-width: 1024px) {
    .goals-container {
        padding: 7.5em 3em;
    }
}


@media only screen and (max-width: 768px) {
.goals-container {
    padding: 7.5em 3em;
}
.goals-content div > div h4{
    width: 76%;
}
.goals-content div > div h4,
.goals-content div > div p {
    font-size: 1.5rem;
    
}
 
}

@media only screen and (max-width: 480px) {
    .goals-container {
        padding: 7.5em 1.5em;
    }
    .goals-content{
        flex-direction: column;
    }
    .goals-content div > div h4{
        width: 100%;
    }
    .goals-content div > div h4
    {
        font-size: 1.5rem;
        
    }
    .goals-content div p{
        font-size: 1rem;
    }
     
    }