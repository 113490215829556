.contact-hero-container{
    background-image: url(../../../assets/contactHero.jpg);
    background-position: center;
    background-size: cover;
    
}
.contact-hero-content{
    padding: 7em 7em;
    display: flex;
   justify-content: space-between;
    background-color: #000000B2;
}
.contact-hero-content_left h1{
  
    font-size:4.5em;
    font-weight: 600;
    line-height: 79.2px;
    letter-spacing: -0.01em;
    text-align: left;
    color: #FFFFFF;
    margin-bottom: 24px;


}
.contact-hero-content_left  h5{

    font-size: 1.5em;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: -0.01em;
    text-align: left;
    color: #F9A410;
    margin-bottom: 24px;

} 
.contact_icon_left{
    display: flex;
    gap: 8px;
    align-items: center;
    margin-bottom: 18px;
}
.contact_icon_left p{
  
    font-size: 0.75em;
    font-weight: 400;
    line-height: 18.6px;
    text-align: left;
    color: #DDD8D0;
    margin-bottom: 4px;

}

.contact_icon_left h6{
   
    font-size: 0.87em;
    font-weight: 500;
    line-height: 21px;
    text-align: left;
    color: #FFFFFF;
}
.contact-hero-content_right{
    background: #FFFFFF;
    padding: 2em;
    gap: 32px;
    border-radius: 12px ;  
}
.contact-hero-content_right  h4{
   
    font-size: 1.75em;
    font-weight: 600;
    line-height: 36.4px;
    text-align: center;
    margin-bottom: 32px;

}
.contact-hero-name {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
}
.contact-hero-name input{  
    height: 44px;
    width: 184px;
    padding:0em .75em;
    outline: none;
    border-radius: 6px;
    border: 1px solid #DDD8D0;
    font-family: Graphik;
    font-size: 14px;
    font-weight: 400;
    line-height: 22.4px;
    letter-spacing: -0.015em;
}
.contact-hero-name input::placeholder,
.contact-hero-texarea textarea::placeholder,
.contact-hero-texarea p
{
   
    font-family: Graphik;
    font-size: 14px;
    font-weight: 400;
    line-height: 22.4px;
    letter-spacing: -0.015em;
    text-align: left;
    color: #B8B3A7;

}
.contact-hero-name h6 {
    
    font-size: 0.88em;
    font-weight: 500;
    line-height: 22.4px;
    text-align: left;
    color: #4B4F57;

}

.contact-hero-texarea h6{
   
    font-size: 0.88em;
    font-weight: 500;
    line-height: 22.4px;
    text-align: left;
    color: #4B4F57;
}
.contact-hero-texarea textarea{
    outline: none;
    border-radius: 6px;
    border: 1px solid #DDD8D0;
    height: 120px;
    padding: .5em .75em;
    width: 95%;
    font-family: Graphik;
    font-size: 14px;
    font-weight: 400;
    line-height: 22.4px;
    letter-spacing: -0.015em;
}
.contact-hero-content_right button{
    font-family: Graphik;
    font-size: .88rem;
    font-weight: 500;
    line-height: 22.4px;
    text-align: center;
    width: 100%;
    height: 40px;
    background: #007EFF;
    color: #FFFFFF;
    padding: 0em 1em;
    border: 1px solid #007EFF;
    border-radius: 8px ;

    outline: none;
    margin-top: 20px;

}
.contact-body-img img{
    width: 100%;
}
.quote-success{
    display: flex;
    align-items: center;
    flex-direction: column;
}
.quote-success div{
    max-width: 300px;
    height: 300px;
}
.quote-success img{
    width: 100%;
}


@media only screen and (max-width: 1024px) {
    .contact-hero-content {
        padding: 5em 3em;
    }

}
@media only screen and (max-width: 960px) {
    .contact-hero-content {
        padding: 5em 3em;
        flex-direction: column;
    }
    .contact-hero-name input{
        width: 286px;
    }

}

@media only screen and (max-width: 768px) {
    .contact-hero-content {
        padding: 5em 3em;
        flex-direction: column;
       
    }
    
    .contact-hero-container{
        background-image: url(../../../assets/contact768.jpg) ;
        
    }
    .contact-hero-name {
       flex-direction: column;
    }
    .contact-hero-content_left h1 {
        font-size: 3.5em;
    }
    .contact-hero-content_left{
        padding: 0em 1em;
    }
    .contact-hero-content_right {
        padding: 2em 3em 2em 1.5em;
    }
    .contact-hero-name input{
        width: 100%;
    }
    .contact-hero-texarea textarea{
        width: 100%;
    }
}

@media only screen and (max-width: 480px) {
    .contact-hero-container{
        background-image: url(../../../assets/contact480.jpg);
        
    }
   
    .contact-hero-content {
        padding: 2em 1.5em;
        gap: 60px
    }
    .contact-hero-content {
      flex-direction: column;
    }
    .contact-hero-name input{
        width: 95%;   
    }
}