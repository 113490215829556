/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

input,textarea{
  font-family: Graphik, Tahoma, serif;
  font-weight: 400;
}
textarea{
  resize: none;
}
textarea::placeholder{
  font-family: Graphik, Tahoma, serif;
  color: rgba(152, 162, 179, 1);
  font-size: 0.85rem;
}
input::placeholder{
  font-family: Graphik, Tahoma, serif;
  color: rgba(152, 162, 179, 1);
  font-size: 0.875rem;
}

@font-face {

  font-family: Graphik;
  src: url('./assets/Graphik_Collection/Graphik-Black-Trial.otf');
    
  font-weight: 900;
  font-style: normal;
}

@font-face {

  font-family: Graphik;
  src: url('./assets/Graphik_Collection/Graphik-Regular-Trial.otf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Graphik;
  src: url('./assets/Graphik_Collection/Graphik-Medium-Trial.otf');
  font-weight: 500; /* Define the font-weight for the 500 variant */
  font-style: normal;
}@font-face {
  font-family: Graphik;
  src: url('./assets/Graphik_Collection/Graphik-Light-Trial.otf');
  font-weight: 300; /* Define the font-weight for the 300 variant */
  font-style: normal;
}

@font-face {
  font-family: Graphik;
  src: url('./assets/Graphik_Collection/Graphik-Medium-Trial.otf');
  font-weight: 500; /* Define the font-weight for the 500 variant */
  font-style: normal;
}

@font-face {
  font-family: Graphik;
  src: url('./assets/Graphik_Collection/Graphik-Semibold-Trial.otf');
  font-weight: 600; /* Define the font-weight for the 600 variant */
  font-style: normal;
}

@font-face {
  font-family: Graphik;
  src: url('./assets/Graphik_Collection/Graphik-Bold-Trial.otf');
  font-weight: 700; /* Define the font-weight for the 700 variant */
  font-style: normal;
}

body {
  font-family: Graphik, Tahoma, serif;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}