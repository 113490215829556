.solution-container{
   
    display: flex;
    background: #FFFFFF;
    padding: 7.5em 7em;
    flex-direction: column;
    gap: 2em;
}
.solution-header > h3{
    font-size: 1rem;
    font-weight: 500;
    
    background: #EEF4FA;
    padding: .3em .8em;
    border-radius: 20px;
    width: 9%;
    color: #4B4F57;
    margin-bottom: 1em;
}
.solution-header > p {
    font-weight:500;
    font-size: 48px;
    line-height: 62px;
    width: 71%;
    
}
.solution-header{
    margin-bottom: 80px;
}
.solution-body{
    display: flex;
    gap: 32px;
}
.solution-box-one{
    padding:3em 3em 0em;
    background: #FEF0D8;
    border-radius: 16px;
    width: 61%;
}
.solution-box-one img{
    width: 100%;
    margin-top:40px;
 
  
}
.solution-box-one h1{
    font-size: 2.5rem;
    font-weight: 500;
    line-height: 62.4px;
    margin-bottom: 22px;
    
}
.solution-box-one p{
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: -0.005em;
    text-align: left;
    color: #272B32;
    
}
.solution-box-two{
    padding:3em 3em;
    background: #C3E1FF;
    border-radius: 16px;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.solution-box-two h1{
    text-align: center;
    font-size: 2.5rem;
    font-weight: 500;
    line-height: 62.4px;
    margin-bottom: 1.6rem
}
.solution-box-two img{
    width: 100%;
  

}
.solution-box-two p{
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 32px;
    text-align: center;
    
}

.solution-box-three{
    padding:3em 3em 2em;
    background: #C3E1FF;
    border-radius: 16px;
    width: 53%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.solution-box-three h1{
    text-align: center;
    font-size: 2.5rem;
    font-weight: 500;
    line-height: 62.4px;
    margin-bottom: 1.6rem
}
.solution-box-three img{
    width: 100%;
    margin-bottom: 40px;
   
}
.solution-box-three p{
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 32px;
    text-align: center;
    
}



.solution-box-four{
    padding:3em 3em 0em;
    background: #FEF0D8;
    border-radius: 16px;
    width: 61%;
}
.solution-box-four img{
    width: 100%;
    margin-top: 50px;
    
}
.solution-box-four h1{
    font-size: 2.5rem;
    font-weight: 500;
    line-height: 62.4px;
    margin-bottom: 22px;
}
.solution-box-four p{
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 32px;
    
}
.solution-btn{
    background: #FFFFFF;
    display: flex;
    align-items: center;
    padding: .5em 1em;
    border-radius: 20px;
    gap: .5em;
    
    height: 24px
}
.solution-btn-cont{
    display: flex;
    justify-content: center;
    margin-top: 1.5em;
}
.solution-btn p{
    color: #0F973D;
    font-size: 14px;
    
}

.underline-bold {
    border-bottom: 7px solid black; 
   
}


@media only screen and (max-width: 1024px) {
    .solution-container{
        padding: 7.5em 3em;
        
    }

    .solution-header > h3{
        width: 12%;
    }
}

  @media only screen and (max-width: 768px) {
    .solution-header > p {
        width: 100%;
        font-size: 2.687rem
        
    }
   
    .solution-container{
        padding: 7.5em 3em;
        
    }
    .solution-header > h3{
        width: 15%;
    }
    .solution-box-four,
    .solution-box-one,
    .solution-box-three,
    .solution-box-two{
      width: 33%; 

      /* margin-bottom: 32px; */
    }
    
}



@media only screen and (max-width: 480px) {

    .solution-body{
        flex-direction: column;
    }
    .solution-header > h3 {
        width: 38%;
    }
    .solution-header > p {
        line-height: normal;
        width: 100%;
        font-size: 2rem;
    }
    .solution-container {
        padding: 7.5em 1.5em;
    }
    .solution-box-four,
    .solution-box-one, 
    .solution-box-three, 
    .solution-box-two {
        width: 90%;
        flex-direction: column;
        margin-bottom: 32px;
        padding: 3em 1em 1em;
    }
}