.services-discover-cont{
    background: #FFFFFF;
    padding: 5em 7em;
    display: flex;
    
    
}

.services-discover-left h1{

    font-size: 3rem;
    font-weight: 600;
    line-height: 52px;
    letter-spacing: -0.005em;
    text-align: left;

}

.services-discover-left h5{
 
font-size: 1.5rem;
font-weight: 500;
line-height: 36px;
letter-spacing: -0.01em;
text-align: left;
color:#4B4F57;
margin: 24px 0px 80px;

}
.services-discover-left img{
    width: 100%;
}

@media only screen and (max-width: 1024px) {
    .services-discover-cont {
        padding: 5em 3em;
    }
   
}
@media only screen and (max-width: 768px) {
    .services-discover-cont {
        gap: 60px;
        padding: 5em 3em;
        flex-direction: column;
       
    }
    
    .services-discover-left h1 {
        font-size: 2.25rem
    }
    
}

@media only screen and (max-width: 768px) {
    .services-discover-cont {
        gap: 60px;
        padding: 5em 1.5em;
        flex-direction: column;
       
    }
    
    .services-discover-left h1 {
        font-size: 2.25rem
    }
    
}