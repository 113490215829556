.values-container{
    padding: 7.5em 7em
}
.values-container h1{
  
    font-size: 3.5rem;
    font-weight: 600;
    line-height: 72.8px;
    letter-spacing: -0.02em;
    text-align: left;
    margin-bottom: 60px;
    color: #000000
}
.values-content{
    display: flex;
    gap: 48px
}
.values-content div{
    display: flex;
    gap: 16px;
    flex-direction: column;
}

.values-content div h4{
 
    font-size: 1.75rem;
    font-weight: 600;
    line-height: 36.4px;
    letter-spacing: -0.01em;
    text-align: left;

}
.values-content div > p{
    padding: .75em;
    border-left:2px solid #F9A410;
    background: #FEF0D8;
    color: #000000;
    height: 100px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 26.4px;
    text-align: left;

}
.values-content div > h5{

    color: #4B4F57;
    
    font-size: 1.12rem;
    font-weight: 400;
    line-height: 30.6px;
    text-align: left;

}
.values-content div img{
    width: fit-content;
}

@media only screen and (max-width: 1024px) {
   
    .values-container {
        padding: 7.5em 3em;
    }
    
}

@media only screen and (max-width: 768px) {
   
    .values-container {
        padding: 7.5em 3em;
    }
    .values-content {
        display: grid;
        grid-template-columns: repeat(2,1fr)
    }
}

@media only screen and (max-width: 480px) {
   
    .values-container {
        padding: 7.5em 1.5em;
    }
    .values-content {
        display: grid;
        grid-template-columns: repeat(1,1fr)
    }
    .values-container h1{
  
        font-size: 2.25rem;
    }
    .values-content > div p{
        height: auto;
    }
}